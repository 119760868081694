import React from 'react';

// Materials UI
import { Grid, Container } from '@material-ui/core';

// Files
import { useTranslate } from 'lambapp-core/core/translate';
import useStyles from './styles';
import translations from './translations';
import LogoWhatsapp from './images/whatsapp-logo.png';

const Footer = () => {
    const classes = useStyles();
    const translate = useTranslate(translations);
    return (
        <footer className={classes.footer}>
            <Container fixed maxWidth="xl">
                <Grid container direction="column">
                    <Grid item container spacing={1}>
                        <Grid item xs={12} sm={4} md={6} container direction="column">
                            <strong>Explorar</strong>
                            <a href="#about-us">{translate.QuienesSomos}</a>
                            <a href="#servicios">{translate.Servicios}</a>
                            <a href="#parteners">{translate.Partners}</a>
                            <a href="#contact-us">{translate.Contactanos}</a>
                        </Grid>
                        <Grid item container xs={12} sm={8} md={6}>
                            <Grid item xs={12} container direction="column">
                                <strong>{translate.Contacto}</strong>
                                <span>Email: <a href="mailto:administracion@megatecgsa.com">administracion@megatecgsa.com</a></span>
                            </Grid>
                            <Grid item xs={12} md={6} container direction="column">
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.copirying} container justify="center">
                        <span>
                            {`Copyright © ${new Date().getFullYear()} `}
                            <a href="/">Megatec Group S.A</a>{translate.FooterText} 
                            <a href="https://api.whatsapp.com/send?phone=+5491130784746&text=Me gustaria informacion sobre el diseño de paginas web" target="_blank" rel="nofollow noopener noreferrer"><img alt="Lambapp" src={LogoWhatsapp} className={classes.imagenWhatsapp} /></a>
                            </span>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    )
}

export default Footer;