import { makeStyles } from "@material-ui/core/styles";
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
  imgContainer: {
    display: "flex"
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "auto"
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 420,
    height:260
  },
  title: {
    color: grey[90],
    fontWeight: 700,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    marginBottom: '1rem',
    marginTop: '2rem',
    fontSize: '2.25rem',
    textAlign: 'center'
  },
  texto:{
      marginBottom: '2rem',
      marginTop: '2rem'
  },
  contenido:{
      marginBottom:'2rem'
  },
  description: {
    color: grey[600],
    textAlign: 'center',
    marginBottom:'2rem'
  },
}));

export default useStyles;