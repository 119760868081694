const translations = {
    servicios: {
        es: "Servicios",
        en: "Services"
    },
    parteners: {
        es: "Clientes",
        en: "Customers"
    },
    contactanos:{
        es:"Contactanos",
        en:"Contact us"
    }
};

export default translations;