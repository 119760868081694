import React from 'react';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import tileData from './tileData';

import { useTranslate } from 'lambapp-core/core/translate';

//Translations
import translations from './translations';
import useStyles from './styles';

export default ({id}) => {
  const classes = useStyles();
  const translate = useTranslate(translations);

  return (
    <Container maxWidth="lg" id={id}>
      <Grid item xs={12} container direction="row" justify="center" alignItems="center" className={classes.root}>
        <Grid item xs={12} container direction='column' justify='center' alignItems='center'>
          <Typography variant="h2" className={classes.title}>
              {translate.Titulo}
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={5} className={classes.whoTrustContainer} alignItems="flex-start">  
          {tileData.map((tile, key) => (
            <Grid key={key} item xs={12} md={6} container direction='column' justify='center' alignItems='center' className={classes.whoTrust}>
              <img className={classes.img} src={tile.img} alt={tile.translateKey} />
              <div className={classes.whoTrustItemText}>
                <Typography variant="body1">{translate[tile.translateKey]}</Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}