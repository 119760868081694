import React from 'react';
import classNames from 'classnames';

// Material UI
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Components
import CustomIcon from 'lambapp-core/core/customIcon';
import { useLanguage, useTranslate } from 'lambapp-core/core/translate';
import translations from './translations';
import useStyles from './styles';

const LanguageSelector = ({onlyIcon, fontsSize, classTitle}) => {
    const classes = useStyles({fontsSize});
    const translate = useTranslate(translations);
    const setLenguage = useLanguage()[1];
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handlerChangeLanguage = (language) => {
        setLenguage(language);
        handleClose();
    }

    return (
        <React.Fragment>
            <Button aria-controls="language-menu" aria-haspopup="true" onClick={handleClick} className={classNames(classes.label, classTitle)}>
                <CustomIcon name="globe" className={classes.icon} />
                {!onlyIcon ? translate.menu : null}
            </Button>
            <Menu id="language-menu" anchorEl={anchorEl} keepMounted
                open={Boolean(anchorEl)} onClose={handleClose} >
                <MenuItem onClick={() => handlerChangeLanguage("es")}>{translate.spanish}</MenuItem>
                <MenuItem onClick={() => handlerChangeLanguage("en")}>{translate.english}</MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default LanguageSelector;