import React from 'react';
// import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslate } from 'lambapp-core/core/translate';
import { makeStyles } from '@material-ui/core/styles';


const translations = {
  procesando: {
      es:"Procesando solicitud",
      en:"Processing"
  }
};

const useStyles = makeStyles({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
});

const SimpleDialog=({openDialogo}) => {

  const classes = useStyles();
  const translate = useTranslate(translations);
  let valor = openDialogo?openDialogo:false;

  return (
    <Dialog open={valor} aria-labelledby="simple-dialog-title">
      <DialogTitle id="simple-dialog-title">{translate.procesando}</DialogTitle>
        <DialogContent className={classes.root}>
            <CircularProgress disableShrink/>
        </DialogContent>
    </Dialog>
  );
}

export default SimpleDialog;