import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    titulo: {
        marginBottom: theme.spacing(2)
    },
    textField: {
        margin: 0,
        background: "#fff"
    }
}));

export default useStyles;