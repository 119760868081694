import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useTranslate } from 'lambapp-core/core/translate';
import ContactUs from "../../components/contactUs";
import useStyles from "./styles";

var translations ={
    Titulo:{
        es:"Contactanos",
        en:"Contact Us"
    }
}
export default function SimpleContainer({id}) {
   const classes = useStyles();
   const translate = useTranslate(translations);
  return (
    <React.Fragment>
      <CssBaseline />
      <Paper>
        <Container maxWidth="lg" id={id}>
          <Grid item xs={12} container direction="row" justify="center" alignItems="center">
            <div className={classes.contenido}>
             <Grid item xs={12} container direction='column' justify='center' alignItems='center'>
                <Typography variant="h2" className={classes.title}>
                    {translate.Titulo}
                </Typography>
                <ContactUs/>
            </Grid>
            </div>
          </Grid>
        </Container>
      </Paper>
    </React.Fragment>
  );
}
