const translations = {
    Nombre:{
        en:"Name",
        es:"Nombre"
    },
    Telefono:{
        en:"Phone",
        es:"Telefono"
    },
    Email:{
        en:"Email",
        es:"Email"
    },
    Mensaje:{
        en:"Message",
        es:"Mensaje"
    },
    Enviar:{
        en:"Send",
        es:"Enviar"
    }
};

export default translations;