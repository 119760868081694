import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    footer: {
        padding: "30px 0",
        minHeight: 200,
        backgroundColor: grey[800],
        color: grey[400],
        fontSize: 14,
        "& a, & strong": {
            marginBottom: theme.spacing(2)
        },
        "& a":{
            color: grey[400],
            textDecoration: 'unset',
            '&:hover, &:active':{
                color: grey[50]
            }
        },
        "& strong":{
            fontSize: 18,
            color: grey[50],
            marginBlockEnd: '1em'
        }
    },
    copirying: {        
        marginTop: 20,
        borderTop: '1px solid',
        padding: 10,
        fontSize: 16,
        "& span": {
            color: grey[300]
        }
    },
    imagenWhatsapp:{
        width:28,
        height:28,
        border:0,
        marginBottom:-5
    }
}), { name: 'footer' });

export default useStyles;