import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    list: {
        width: 240
    },
    icon: {
        width: 30,
        height:30 
    },
    menu: {
        fontSize: '1.5rem',
        color: "#fff"
    },
    title: {
        fontSize: 22,
        fontWeight: 500
    }
}), { name: 'navbarMenu' });

export default useStyles;