/* SmtpJS.com - v3.0.0 */
export default class SmtpService {
    send = (a) => { 
            return new Promise((n, e) =>
            { 
                a.nocache = Math.floor(1e6 * Math.random() + 1); 
                a.Action = "Send"; 
                let t = JSON.stringify(a); 
                this.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) 
            }); 
        }; 

    ajaxPost = (e, n, t) => { 
        let a = this.createCORSRequest("POST", e); 
        a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"); 
        a.onload = () => { 
            let e = a.responseText; 
            null != t && t(e) 
        }; 
        a.send(n); 
    }; 

    ajax = (e, n) => { 
        let t = this.createCORSRequest("GET", e); 
        t.onload = () => { 
            let e = t.responseText; 
            null != n && n(e); 
        }; 
        t.send() 
    }; 

    createCORSRequest = (e, n) => { 
        let t = new XMLHttpRequest(); 
        return (
            'withCredentials' in t 
            ? t.open(e, n, !0) 
            : (t = null), t); 
    };

    sendWithAttachment = (e, o, t, n, a, s, r, c, d) => {
            let i = Math.floor(1e6 * Math.random() + 1);
            let m = `From=${e}&to=${o}&Subject=${encodeURIComponent(
                t
            )}&Body=${encodeURIComponent(n)}&Attachment=${encodeURIComponent(c)}`;

            if (!a.token) {
                m += `&Host=${a}&Username=${s}&Password=${r}&Action=Send`;
            } else {
                m += `&SecureToken=${a.token}&Action=SendFromStored`;
            }

            m += `&cachebuster=${i}`;

            this.ajaxPost('https://smtpjs.com/v2/smtp.aspx?', m, d);
        };
}
