const translations = {
    Titulo: {
        es:"MegaTec Group S.A.",
        en:"MegaTec Group S.A."
    }, 
    SubDescripcion: 
    { 
        es:"Somos una empresa dedicada a brindar servicios de tendido, fusión y mantenimiento técnico, sobre todo de servicios de comunicación en tecnología de Fibra Optica. También realizamos la instalación domiciliaria de servicios de Internet y Cable en Fibra Optica. El área de influencia de nuestra empresa abarca una parte importante del país, incluyendo personal técnico en zonas como CABA, AMBA Sur, Oeste y Norte",
        en:"We are a company that is dedicated to provide laying services, merger and technical maintenance, especially communication services in Fiber Optic Technology. Also, perform the home installation of Internet and Cable services in Fiber Optics. The influence that the company covers include an important part of Argentina, including technical personnel in areas such as CABA and South, West and North AMBA."
    },
    Descripcion:{
        es:"Nuestro reto es convertirnos en el proveedor de servicios de red de referencia para las empresas de comunicaciones y energía en los mercados en los que operamos, creando valor de forma sostenible para nuestros clientes, trabajadores, accionistas y las comunidades donde interactuamos.",
        en:"Megatec challenge is to become the reference network service provider for communications and energy companies in the markets in which Megatec operate, creating value in a sustainable way for our customers, workers, shareholders and the communities where Megatec interact."
    }
};

export default translations;
