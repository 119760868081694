import React from 'react';

// Components
import Navbar from 'lambapp-core/core/navbar';
import ImageContainer from 'lambapp-core/core/imageContainer';
import Navegation from '../../views/shared/navegation';
import Grid from '@material-ui/core/Grid';
import Conainer from '@material-ui/core/Container';

// Files
import Logo from "./images/logo.png";
import BGHeader from './images/header.png';
import HeaderLogo from './images/logo-header.png';
import useStyles from './styles';

const Header = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <ImageContainer bgImage={BGHeader} bgShadow={0.2}>
                <Conainer fixed maxWidth="xl" className={classes.root}>
                    <Grid className={classes.content} container direction="row" justify="center" alignContent="center">
                        <img alt="Megatec" src={HeaderLogo} className={classes.image} />
                    </Grid>
                </Conainer>
            </ImageContainer>        
            <Navbar brand={{ label: "Megatec", image: Logo }} colors={{ navbar: "#18437d", text: "#fff" }}>
                <Navegation />
            </Navbar>
        </React.Fragment>
    )
};

export default Header;