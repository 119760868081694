import React, { useState } from 'react';

// Materials UI
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Components
import { useTranslate } from 'lambapp-core/core/translate';
import SmtpService from '../../smtp';
import translations from './translations';
import useStyles from './styles';
import SimpleDialog from '../preloader';

const ContactUs = () => {
    const translate = useTranslate(translations);
    const classes = useStyles();
    

    const [openDialog, setOpenDialog] = useState(false);
    const [contactanos, setContactanos]  = useState({
        nombreError: false, 
        emailError: false,
        mensajeError: false,
    });

    const handleChange = (event) => {
        validarCampoVacio(event.target.id, event.target.value);
    }

    const validarCampoVacio = (id, value) => {     
        setContactanos({[`${id}Error`]: (!value)});
        return !(!value)
    }

    const verificarFormulario = () => {
        return (
            validarCampoVacio("nombre",document.getElementById("nombre").value) &&
            validarEmail("email",document.getElementById("email").value) &&
            validarCampoVacio("mensaje",document.getElementById("mensaje").value)
        )
    }

    const validarEmail = (id, email) => {
        var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var valido = re.test(String(email).toLowerCase());
        setContactanos({ [`${id}Error`]: !valido });
        return valido;
    }

    const onSubmit = () => {
        if(verificarFormulario()){
            handlePreload(true);
            sendEmail();
        }
    }
    const sendEmail = () => {
        let telef = document.getElementById("telefono").value;
        let sender = new SmtpService();
        sender.send({
            SecureToken : "b3db5ba5-4d39-4e81-b17c-3a1a9c03659a",
            To : 'administracion@megatecgsa.com',
            From : document.getElementById("email").value,
            Subject : "web Megatec  - Contacto: " + document.getElementById("nombre").value + telef,
            Body : document.getElementById("mensaje").value
        }).then(message => resultadoEnvio(message));
    };

    const resultadoEnvio = (message) =>{
        console.log(message);
        if(message=== "OK"){
            alert("Su consulta fue enviada exitosamente!");
            limpiarFormulario();
        }else{
            alert("Ocurrio un error al enviar su consulta, por favor intente mas tarde o contactenos via email o telefono");
            limpiarFormulario();
        }
        handlePreload(false);
    }

    const handlePreload= (valor)=>{
        setOpenDialog(valor);
      }

    const limpiarFormulario= ()=> {
            document.getElementById("email").value = "";
            document.getElementById("nombre").value = "";
            document.getElementById("telefono").value = "";
            document.getElementById("mensaje").value = "";
    }

    return (
    <div>  
        <Grid container>
            <Grid container item justify="center" spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TextField id="nombre" onChange={handleChange} className={classes.textField} required error={contactanos.nombreError} fullWidth label={translate.Nombre} margin="normal" variant="outlined" />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField id="telefono" onChange={handleChange} className={classes.textField} fullWidth label={translate.Telefono} margin="normal" variant="outlined" />
                </Grid>  
                
                <Grid item xs={12} sm={12}>
                    <TextField id="email" onChange={(e) => validarEmail(e.target.id, e.target.value)} className={classes.textField} required error={contactanos.emailError} fullWidth label={translate.Email} margin="normal" variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <TextField id="mensaje" onChange={handleChange} className={classes.textField} required error={contactanos.mensajeError} label={translate.Mensaje} multiline rows="10" fullWidth margin="normal" variant="outlined"/>
                </Grid>
                <Grid container item xs={12} justify="flex-end">
                    <Button variant="contained" type="submit" onClick={onSubmit} color="primary" className={classes.button}>{translate.Enviar}</Button>
                </Grid>
            </Grid>
        </Grid>
        <SimpleDialog openDialogo={openDialog} />
    </div>
    )
};

export default ContactUs;