import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    titulo:{
        display: 'flex',
        alignItems: 'center'
    },
    icon: ({iconColor}) => ({
        marginRight: 10,
        fontSize: 28,
        color: iconColor
    })
}), { name: 'feature' });

export default useStyles;
