const data = {
  titulo: {
    es: "Servicios",
    en: "Services"
  },
  descripcion: {
    es: `Contamos con la capacidad de poder cumplir
    con la totalidad de los requisitos de nuestros clientes, generando
    valor, confianza y beneficios más allá del cumplimiento básico de los objetivos.`,
    en: `Megatec is in the position to successfully complete
    all the requirements by the client.
    Also, Megatec has the ability to perform value added activities, 
    with which provide your company
    with benefits beyond the basic fulfillment of the objectives.`
  },
  features: [{
    titulo: {
      es: "Personal Tecnico",
      en: "Technical staff"
    },
    descripcion: {
      es: `Dentro de nuestro staff, contamos con 45 técnicos especializados
        en la Instalacion Domiciliaria de tecnologías como Cobre, GPON,
        FTTH, FIBRA OPTICA con varios años de experiencia.\n
        \n * Cuadrillas de Tendido de FIBRA OPTICA.\n * Cuadrillas especialistas en empalmes y fusión de FO.\n * Cuadrillas de Mantenimiento y Reparo de redes de Telecomunicaciones.\n * cuadrilla de Grúa, instalación, desinstalación de postes`,
      en: `Megatec has 45 specialized technicians on the Installation of
      technologies on homes such as Copper, GPON, FTTH, FIBER OPTICA, with
      several years of experience.\n
      \n * FIBER OPTIC Laying Teams.\n * Specialized crews in splicing and FO fusion.\n * Maintenance and Repair groups of Telecommunications networks.\n * Crane crew, installation, uninstallation of poles`
    },
    icon: {
      name: "users",
      color: "#00acc1"
    }
  },
  {
    titulo: {
      es: "Transporte",
      en: "Transport"
    },
    descripcion: {
      es: `Contamos con una flota de utilitarios para nuestros tecnicos y especialistas.\n
      \n * Renault Kangoo\n * Citroen Berlingo\n * Ford KA\n * Volkswagen Gol\n * Chevrolet Celta\n * Fiat Uno Cargo`,
      en: `We have a fleet of utilities for our technicians and specialists.\n
      \n * Renault Kangoo\n * Citroen Berlingo\n * Ford KA\n * Volkswagen Gol\n * Chevrolet Celta\n * Fiat Uno Cargo`
    },
    icon: {
      name: "shuttle-van",
      color: "#4caf50"
    }
  },
  {
    titulo: {
      es: "Equipamiento especializado",
      en: "Specialized equipment"
    },
    descripcion: {
      es: `Nuestros tecnicos tambien cuentan con el siguiente equipamiento.\n
      \n * Kits de empalme de fibra óptica\n * Escaleras Dieléctricas\n * Fusionadora de FO\n * OTDR\n * Roto percutor\n * Martillos Neumáticos\n * Herramientas ligeras\n * Equipos de seguridad`,
      en: `Our technicians also have the following equipment.\n
      \n * Fiber optic splice kits.\n * Dielectric stairs\n * FO Fusion Machine\n * OTDR\n * Roto percutor\n * Pneumatic Hammers\n * Light tools\n * Security equipments`
    },
    icon: {
      name: "toolbox",
      color: "#f44336"
    }
  }]
}

export default data;