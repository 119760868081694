import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '30px 0',
        marginBottom:'2rem',
        '& h4': {
            marginBottom: 14,
            fontSize: '1.125rem'
        },
        '& h2': {
            fontSize: '2.25rem',
            marginBottom: 25
        },
        '& h2, & h4': {
            color: '#FFF',
            fontFamily: '"Roboto Slab", "Times New Roman", serif',
            fontWeight: 700
        },
        '& h5': {
            fontSize: '1.0625rem'
        },
        '& p': {           
            fontSize: 14,
        },
        '& li': {
            fontSize: 12
        },
        '& h5, & p, & li': {
            fontWeight: 600,
            color: '#FFF'
        },
        '& h1, & h4, & h5, & p, & li': {
            lineHeight: '1.5em'
        }
    },
    titulo: {
        marginBottom: 25,
        marginTop: 25
    },
    feature: {
        padding: 12
    }
}), { name: 'features-section' });

export default useStyles;