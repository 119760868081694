import React from 'react';
import { useTranslate } from 'lambapp-core/core/translate';
import Feature from '../../components/feature/';
import ImageContainer from 'lambapp-core/core/imageContainer';
// Material UI
import { Typography, Grid } from '@material-ui/core';
import Conainer from '@material-ui/core/Container';

import useStyles from './styles';
import data from '../../data/features';
import BGHeader from './images/fibra-optica.jpg';

const Features = ({id}) => {
    const translate = useTranslate({
        titulo: data.titulo,
        descripcion: data.descripcion
    });

    const classes = useStyles();

    return(
        <ImageContainer bgImage={BGHeader} bgShadow={0.5}>
            <Conainer fixed maxWidth="xl" id={id}>            
                <Grid container direction="column" justify="center" alignItems="center" className={classes.root}>
                    <Grid item xs={12} lg={8} className={classes.titulo}>
                        <Typography variant="h2" align="center">{translate.titulo}</Typography>
                        <Typography variant="h5" align="center">{translate.descripcion}</Typography>
                    </Grid>
                    <Grid item container direction="row" justify="center" alignItems="flex-start">
                        {data.features.map((item, key) => (
                            <Grid key={key} item xs={12} md={6} lg={4} container direction="column" justify="center" className={classes.feature}>
                                <Feature titulo={item.titulo} descripcion={item.descripcion} icon={item.icon} />
                            </Grid>))}
                    </Grid>
                </Grid>
            </Conainer>
        </ImageContainer>
    )
};

export default Features;