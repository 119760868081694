import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    label:{
        fontSize: ({fontsSize}) => fontsSize.text || '0.875rem',
        minWidth: 'unset'
    },
    icon: {
        fontSize: ({fontsSize}) => fontsSize.icon || 18,
        marginRight: 5,
        color: "#fff"
    }
}), { name: 'languageSelector' });

export default useStyles;
