import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { useTranslate } from 'lambapp-core/core/translate';

import ImageAboutUs from './images/conexion-bajada-externa.png';
//Translations
import translations from './translations';
import useStyles from './styles';

export default ({id}) => {
  const classes = useStyles();
  const translate = useTranslate(translations);

  return (
    <Container maxWidth="lg" id={id}>
      <Grid item xs={12} container direction="row" justify="center" alignItems="center" className={classes.contenido}>
          <Grid item xs={12} container direction='column' justify='center' alignItems='center'>
            <Typography variant="h1" className={classes.title}>
                {translate.Titulo}
            </Typography>
            <Typography variant="body1" className={classes.description}>{translate.SubDescripcion}</Typography>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6} className={classes.imgContainer}>
              <img className={classes.img} alt="MegaTec Group S.A." src={ImageAboutUs} />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.texto}>
              <Typography variant="body1" paragraph>
                {translate.Descripcion}
              </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}