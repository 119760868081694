import React from 'react';
import ReactDOM from 'react-dom';
import App from './components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './components/theme';
import { TranslateProvider } from 'lambapp-core/core/translate';

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <TranslateProvider languages={['en', 'es']} defaultLanguage={'es'}>
            <App />
        </TranslateProvider>
    </MuiThemeProvider>
, document.getElementById('root'));
