import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  breakpoints: {
    keys:['xs','sm','md','lg','xl'],
    values:{
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
  },
  palette: {
    background: {
      default: "#f2f2f2"
    }
  },
  typography: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif'
  }
});

export default theme;