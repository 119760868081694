import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginBottom:'2rem',
    width: 'auto',
    height:'auto',
  },
  gridList: {
    height: 510
  },
  titleGridBar: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  description: {
    color: grey[600],
    textAlign: 'center',
    marginBottom:'2rem'
  },
  title: {
    color: grey[90],
    fontWeight: 700,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    marginBottom: '2rem',
    marginTop: '3rem',
    fontSize: '2.25rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 320,
    height:90,
    marginBottom: '1rem',
  },
  whoTrust:{
    width: 'auto',
    height:'auto',
  },
  whoTrustContainer:{
    marginBottom:'3rem'
  },
  whoTrustItemText:{
    width: 'auto',
    height:'auto',
    marginLeft:5,
    marginRight:5
  },
}));

export default useStyles;