import React from 'react';

// Components
import Header from './header';
import Footer from './footer';

// Secciones
import Features from '../views/features';
import AboutUs from '../views/aboutUsSection';
import WhoTrustUs from '../views/whoTrustUsSection';
import ContactUs from '../views/contactUsSection';
import GoTop from '../components/goTop';
// Files
import global from './styles';

// Materials UI
import { withStyles } from "@material-ui/styles/";

const App = () => {
    return (
    <React.Fragment>
        <Header />          
        <AboutUs id="about-us"/>
        <Features id="servicios"/>
        <WhoTrustUs id="parteners"/>
        <ContactUs id="contact-us"/>
        <GoTop scrollY={200} />
        <Footer />
    </React.Fragment>)
};

export default withStyles(global)(App);