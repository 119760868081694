import React, { useEffect, useState } from 'react';
import showdown  from 'showdown';

// Components
import { useTranslate } from 'lambapp-core/core/translate';
import CustomIcon from 'lambapp-core/core/customIcon';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const Feature = ({ titulo, descripcion, icon }) => {
    const classes = useStyles({iconColor: icon.color});
    const translate = useTranslate({ titulo, descripcion });
    const [descripcionHtml, setDescripcionHtml] = useState("");

    useEffect(() => {
        let convert = new showdown.Converter();
        let descripcion = convert.makeHtml(translate.descripcion);
        setDescripcionHtml(descripcion);
    }, [translate])

    return(
        <React.Fragment>
            <Typography variant="h4" className={classes.titulo}>
                <CustomIcon name={icon.name} className={classes.icon}/>{translate.titulo}
            </Typography>
            <span dangerouslySetInnerHTML={{__html: descripcionHtml}}></span>
        </React.Fragment>
    )
};

export default Feature;