const translations = {
    menu: {
        es: "Idioma",
        en: "Language"
    },
    spanish: {
        es: "Español",
        en: "Spanish"
    },
    english: {
        es: "Ingles",
        en: "English"
    }
};

export default translations;