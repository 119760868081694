import React, { useState, useEffect } from 'react';
import CustomIcon from 'lambapp-core/core/customIcon'
import useStyles from './styles';

const GoTop = ({ scrollY }) => {

    const [pageYOffset, setPageYOffset] = useState(0);
    const classes = useStyles();
    const classRoot = pageYOffset > scrollY
        ? `${classes.root} ${classes.show}`
        : classes.root;

    useEffect(() => 
    {
        const handleScroll = () => setPageYOffset(window.pageYOffset);

        window.addEventListener("scroll", handleScroll);
        
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className={classRoot} onClick={() => window.scrollTo(0, 0)}>
            <CustomIcon name="arrow-up" className={classes.icon} />
        </div>
    )
}

export default GoTop;