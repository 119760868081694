import { makeStyles } from "@material-ui/core/styles";
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
    title: {
      color: grey[90],
      fontWeight: 700,
      fontFamily: '"Roboto Slab", "Times New Roman", serif',
      marginBottom: '1rem',
      marginTop: '2rem',
      fontSize: '2.25rem'
  },
  contenido:{
      marginBottom:'2rem'
  },
  }));

export default useStyles;