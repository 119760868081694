/**
 * The example data is structured as follows:
 **/
  import telefonica from './images/telefonica-movistar.png';
  import ezentis from './images/ezentis.png';
  import odebrecht from './images/odebrecht.png';
  import corven from './images/corven.jpg';
  
  const tileData = [
    {
        img: telefonica,
        translateKey: 'telefonica' 
    },
    {
       img: ezentis,
       translateKey: 'ezentis'
    },
    {
        img: odebrecht,
        translateKey: 'odebrecht'
     },
     {
         img:corven,
         translateKey:'corven'
     }
];

export default tileData;