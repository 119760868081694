import React from 'react';

import LanguageSelector from '../../../components/languageSelector/';
import { useTranslate } from 'lambapp-core/core/translate';

// Materials UI
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from './menu';

// Files
import Logo from "./images/logo.png";
import translations from './translations';
const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        fontSize: 16
    }
}));

const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />;
}

const Navegation = () => {
    const theme = useTheme();
    const classes = useStyles();
    const translate = useTranslate(translations)
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    
    return (
        <Grid container direction="row" justify="flex-end" alignItems="center">
            <LanguageSelector onlyIcon={!matches} fontsSize={{text: 16, icon: 18}}/>
            { matches ? (
                    <React.Fragment>
                        <Button className={classes.button} href="#servicios">{translate.servicios}</Button>
                        <Button className={classes.button} href="#parteners">{translate.parteners}</Button>
                        <Button className={classes.button} href="#contact-us">{translate.contactanos}</Button>
                    </React.Fragment>
                ) : (
                    <Menu title={"Megatec"} icon={Logo}>
                        <ListItemLink href="#servicios">
                            <ListItemText primary={translate.servicios} />
                        </ListItemLink>
                        <ListItemLink href="#parteners">
                            <ListItemText primary={translate.parteners} />
                        </ListItemLink>
                        <ListItemLink href="#contact-us">
                            <ListItemText primary={translate.contactanos} />
                        </ListItemLink>
                    </Menu>)
            }            
        </Grid>
    )
}

export default Navegation;