
const translations = {
    QuienesSomos:{
        en:"About us",
        es:"Quienes somos"
    },
    Servicios:{
        en:"Services",
        es:"Servicios"
    },
    Partners:{
        en:"Customers",
        es:"Quienes confian en nosotros"
    },
    Contactanos:{
        en:"Contact us",
        es:"Contactanos"
    },
    Contacto:{
        en:"Write us",
        es:"Escribinos"
    },
    DescripcionContacto:{
        en:"Commercial management and operations:",
        es:"Gerencia comercial y operaciones:"
    },
    FooterText:{
        en:" All Rights Reserved | Developed by Lambapp. Do you want your website? Free quote, by clicking here",
        es:" Todos los derechos reservados | Desarrollado por Lambapp. ¿queres tu Web? cotiza sin cargo, haciendo click aca"
    }
}

export default translations;