import { makeStyles } from '@material-ui/styles';

const noSelected = {
    '-webkit-user-select': 'none', 
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
};

const transation = (sec) => ({
    '-webkit-transition': `all ${sec}s ease`,
    '-moz-transition': `all ${sec}s ease`,
    '-o-transition': `all ${sec}s ease`,
    '-ms-transition': `all ${sec}s ease`,
    transition: `all ${sec}s ease`
});

const useStyles = makeStyles(() => ({    
    root: {
        position: 'fixed',
        bottom: 20,
        right: -50,
        background: 'rgba(0, 0, 0, 0.7)',
        width: 50,
        height: 50,
        display: 'block',
        textDecoration: 'none',
        '-webkit-border-radius': 35,
        '-moz-border-radius': 35,
        borderRadius: 35,
        zIndex: 99,
        ...transation(0.5),
        cursor: 'pointer',
        ...noSelected
    },
    show: {
        right: 20
    },
    icon: {
        color: '#fff',
        margin: 0,
        position: 'relative',
        left: 16,
        top: 13,
        fontSize: 19,
        ...noSelected
    },
}), { name: 'go-top' })

export default useStyles;