import { makeStyles } from '@material-ui/styles';

const global = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.default,
      margin: 0,
      padding: 0,
      fontFamily: theme.typography.fontFamily,
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale"
    }
  }
}));

export default global;