import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: 250
    },
    content: {
        height: "100%"
    },
    image: {
        height: '75%'
    }
}));

export default useStyles;