import React, { useState } from 'react';

// Components
import CustomIcon from 'lambapp-core/core/customIcon';

// Materials UI
import { IconButton, Drawer, Divider, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

// Files
import useStyles from './styles';

const Menu = ({title, icon, children}) => {  
    const classes = useStyles();
    const [drawerActive, setDrawerActive] = useState(false);

    return (   
        <div>            
            <IconButton  color="inherit" onClick={() => setDrawerActive(true)}>
                <CustomIcon className={classes.menu} name="bars" />
            </IconButton >  
            <Drawer open={drawerActive} onClose={() => setDrawerActive(false)}>
                <div tabIndex={0} role="button" onClick={() => setDrawerActive(false)}
                    onKeyDown={() => setDrawerActive(false)}>
                    <List className={classes.list}>
                        <ListItem title={title}>   
                            <ListItemIcon><img className={classes.icon} alt={title} src={icon}/></ListItemIcon>                         
                            <ListItemText primary={title} />
                        </ListItem>
                        <Divider />
                        {children}
                    </List>
                </div>
            </Drawer>
        </div>   
    )
}

export default Menu;