const translations = {
    Titulo: {
        es:"Quienes confian en nosotros",
        en:"Who trust us"
    }, 
    Descripcion: 
    { 
        es:"Somos una empresa dedicada a brindar servicios de tendido, fusión y mantenimiento técnico, sobre todo de servicios de comunicación en tecnología de Fibra Optica. También realizamos la instalación domiciliaria de servicios de Internet y Cable en Fibra Optica. El área de influencia de nuestra empresa abarca una parte importante del país, incluyendo personal técnico en zonas como CABA, AMBA Sur, Oeste y Norte",
        en:"We are a company that is dedicated to provide laying services, merger and technical maintenance, especially communication services in Fiber Optic Technology. Also, perform the home installation of Internet and Cable services in Fiber Optics. The influence that the company covers include an important part of Argentina, including technical personnel in areas such as CABA and South, West and North AMBA."
    },
    SubDescripcion:{
        es:"Nuestro reto es convertirnos en el proveedor de servicios de red de referencia para las empresas de comunicaciones y energía en los mercados en los que operamos, creando valor de forma sostenible para nuestros clientes, trabajadores, accionistas y las comunidades donde interactuamos.",
        en:"Megatec challenge is to become the reference network service provider for communications and energy companies in the markets in which Megatec operate, creating value in a sustainable way for our customers, workers, shareholders and the communities where Megatec interact."
    },
    telefonica: {
        es:"Telefónica Moviles Argentina SA en la migración de su red a FTTH (FO) desde el año 2017 a la actualidad. Realizando el tendido y fusión de su red en todo CABA y GBA. Como así también la instalación domiciliaria de FTTH e IpTV",
        en:"Telefónica Moviles Argentina SA in the migration of their network to FTTH (FO) since 2017. Carrying out the laying and fusion of their network in CABA and GBA. As well as the home installation of FTTH and IpTV"
    },
    ezentis:{
        es:"Ezentis Argentina S.A. realizamos tareas de Tendido y Fusión de FO. También reparaciones y emergencias de FO.",
        en:"With Ezentis Argentina S.A, Megatech is responsable of FO Landing and Fusion tasks and with FO repairs and emergencies."
    },
    odebrecht:{
        es:"ODEBRECHT (Central Termoelectrica Timbues) hemos realizado la fusion de los anillos de FO para los 3 Centro de Computos dando servicio de conectividad a la Termoelectrica.",
        en:"With ODEBRECHT (Timbues Thermoelectric Plant) Megatec have made the fusion of FO rings for the 3 Computing Centers giving connectivity service to the Thermoelectric."
    },
    corven:{
        es:"Corven Motors Argentina S.A en su centro industrial se realizó el tendido y fusión de FO para conectar su centro de cómputos y se instaló e implemento el sistema de vigilancia por vídeo cámaras.",
        en:"With Corven Motors Argentina S.A, Megatec has worked in their industrial center on laying and mergered of FO to connected its computer center. Also, cameras and video surveillance systemn were implemented and installed."
    } 
};

export default translations;
